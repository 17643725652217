/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import { useFormatCurrency } from '@/hooks/useFormat';
import { ChevronIcon } from '@/theme/Icons';
import {
  HStack,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  TextProps,
  Th,
  Thead,
  Tr,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import {
  Column,
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { HTTP } from '../Http';
import useMediaQuery from '@/hooks/useMediaQuery';

type DataTableProps = TableProps & {
  fetchUrl: string;
  columns: Array<Column>;
  alignAmount?: 'left' | 'right' | 'center';
  selectRow?: (row: any) => void;
  hideSortArrowWhenNoSortOnMobile?: boolean;
};

export const getStatusColor = (status: Statuses) => {
  switch (status) {
    case 'processing':
    case 'ready_to_be_collected':
      return '#FFF';
    case 'refused':
    case 'error':
      return '#F00050';
    case 'paid':
      return '#50C878';
    case 'pending':
    case 'cancelled':
    default:
      return '#808080';
  }
};

export function StatusCell({ value }: { value: Statuses }) {
  const { t } = useTranslation();
  return (
    <Text
      fontWeight="bold"
      border={`2px solid ${getStatusColor(value)}`}
      color={getStatusColor(value)}
      borderRadius="1.5rem"
      padding="0.5rem 0.7rem"
      width="fit-content"
      textTransform="uppercase"
      data-testid="statusCell"
    >
      {t(`wallet:${value}`)}
    </Text>
  );
}

export const TypeCell = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  return (
    <>
      {t('wallet:withdrawal')}
      <br />
      {t(`wallet:${value.toLowerCase()}_type`)}
    </>
  );
};

export function AmountCell({
  value,
  placeHolder,
  ...props
}: TextProps & { value: number; placeHolder?: string }) {
  const amount = useFormatCurrency({ amount: value });
  return (
    <Text {...props}>
      {!value && placeHolder !== undefined ? placeHolder : amount}
    </Text>
  );
}

export type Statuses =
  | 'paid'
  | 'processing'
  | 'ready_to_be_collected'
  | 'pending'
  | 'cancelled'
  | 'refused'
  | 'error';

const DataTable = forwardRef(
  (
    {
      fetchUrl,
      columns,
      selectRow,
      hideSortArrowWhenNoSortOnMobile = false,
      ...props
    }: DataTableProps,
    ref
  ) => {
    const [data, setData] = useState<any[]>([]);
    const [numberOfPages, setNumberOfPages] = useState(1);
    // const [types, setTypes] = useState<Types[]>([]);
    // const [statuses, setStatuses] = useState<Statuses[]>([]);
    //   const [selectedRow, setSelectedRow] = useState<Transaction | null>(null);
    const { t } = useTranslation();
    const toast = useToast();
    const isMobile = useMediaQuery('(max-width: sm)');

    useImperativeHandle(ref, () => ({
      fetchData: () => fetchDataTable()
    }));

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      previousPage,
      nextPage,
      canPreviousPage,
      canNextPage,
      state: { pageIndex, pageSize, sortBy }
    } = useTable(
      {
        columns: columns,
        data,
        manualSortBy: true,
        manualPagination: true,
        initialState: { pageIndex: 0, pageSize: 20 },
        pageCount: numberOfPages
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );

    const fetchDataTable = useCallback(async () => {
      try {
        const order = sortBy[0]
          ? `&order[${sortBy[0].id}]=${sortBy[0].desc ? 'desc' : 'asc'}`
          : '&order[createdAt]=desc';
        const url = `${fetchUrl}?page=${pageIndex + 1}&limit=20${order}`;
        const { data } = await HTTP.get(url, {
          headers: {
            Accept: 'application/ld+json'
          }
        });
        setData(data['hydra:member']);
        setNumberOfPages(
          Math.ceil(
            data['hydra:totalItems'] / pageSize === 0
              ? 1
              : data['hydra:totalItems'] / pageSize
          )
        );
      } catch (error: any) {
        toast({
          status: 'error',
          title: error?.data?.title || t('common:somethingWrongHappenned'),
          description: error?.data?.detail || null,
          position: 'top-right'
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sortBy]);

    useEffect(() => {
      fetchDataTable();
    }, [fetchDataTable, sortBy]);

    // const handleType = (e: ChangeEvent<HTMLInputElement>) => {
    //   setTypes([...types.filter((type) => type !== e.target.name)]);
    //   if (e.target.checked) {
    //     setTypes([...types, e.target.name as Types]);
    //   }
    // };

    // const handleStatus = (e: ChangeEvent<HTMLInputElement>) => {
    //   setStatuses([...statuses.filter((status) => status !== e.target.name)]);
    //   if (e.target.checked) {
    //     setStatuses([...statuses, e.target.name as Statuses]);
    //   }
    // };

    const clickOnRow = (row: any) => {
      selectRow?.(row);
      // setSelectedRow(row);
      // onOpen();
    };

    return (
      <VStack
        align="baseline"
        className="no-scrollbar"
        overflow="auto"
        {...props}
      >
        {/* <HStack alignSelf="flex-end" marginBottom="1rem">
        <Menu closeOnSelect={false} isLazy>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<ChevronIcon width={5} height={5} />}
          >
            {t('wallet:chooseAType')}
          </MenuButton>
          <MenuList border="1px solid rgba(142, 148, 179, 0.2) !important">
            {staticTypes.map((type) => (
              <MenuItem key={type} as={HStack}>
                <Checkbox
                  colorScheme="red"
                  isChecked={types.includes(type as Types)}
                  name={type}
                  onChange={handleType}
                  textTransform="capitalize"
                >
                  {t(`wallet:${formatCashInOut(type)}`)}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <InputGroup maxWidth="13rem">
          <Input
            borderColor="transparent"
            _focus={{ borderColor: 'rgba(255,255,255,0.3)' }}
            _hover={{ borderColor: 'rgba(255,255,255,0.3)' }}
            placeholder="Search transaction"
            _placeholder={{
              color: '#FFF'
            }}
          />
          <InputRightElement>
            <SearchIcon width={5} height={5} />
          </InputRightElement>
        </InputGroup>
        <Menu closeOnSelect={false} isLazy>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<FilterIcon width={5} height={5} />}
          >
            {t('wallet:filterStatus')}
          </MenuButton>
          <MenuList border="1px solid rgba(142, 148, 179, 0.2) !important">
            {staticStatuses.map((status) => (
              <MenuItem key={status} as={HStack}>
                <Checkbox
                  colorScheme="red"
                  isChecked={statuses.includes(status as Statuses)}
                  name={status}
                  onChange={handleStatus}
                  textTransform="capitalize"
                >
                  {t(`wallet:${status}`)}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </HStack> */}

        <Table
          variant="unstyled"
          {...getTableProps()}
          className="chakra-table--custom"
        >
          <Thead position="sticky" top="0" left="0" color="border.60">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      paddingX="1rem"
                      py={'2'}
                      borderColor="#C2C2C2"
                      color="#C2C2C2"
                      borderBottom={'1px solid'}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      paddingInlineStart={[1, 4]}
                      paddingInlineEnd={[1, 4]}
                    >
                      <HStack width="100%">
                        <Text>{column.Header?.toString()}</Text>
                        {column?.isSorted ? (
                          column.isSortedDesc ? (
                            <ChevronIcon
                              ml={'auto'}
                              color="customGrey.900"
                              width={6}
                              height={6}
                            />
                          ) : (
                            <ChevronIcon
                              ml={'auto'}
                              transform="rotate(180deg)"
                              color="customGrey.900"
                              width={6}
                              height={6}
                            />
                          )
                        ) : isMobile &&
                          hideSortArrowWhenNoSortOnMobile ? null : (
                          <ChevronIcon
                            ml={'auto'}
                            opacity="0"
                            width={6}
                            height={6}
                          />
                        )}
                      </HStack>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row: Row<{}>) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{
                    cursor: 'pointer'
                  }}
                  onClick={() => clickOnRow(row?.original)}
                  onMouseEnter={(e) => {
                    const previousSiblingElement = (e?.target as HTMLElement)
                      ?.parentElement?.previousElementSibling;

                    previousSiblingElement?.classList.add('no-border');
                  }}
                  onMouseLeave={(e) => {
                    const previousSiblingElement = (e?.target as HTMLElement)
                      ?.parentElement?.previousElementSibling;

                    previousSiblingElement?.classList.remove('no-border');
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        border="0 0 1px 0"
                        borderBottomColor="border.20"
                        color="white"
                        py="5"
                        paddingInlineStart={[1, 4]}
                        paddingInlineEnd={[1, 4]}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <HStack
          marginTop="2rem !important"
          alignSelf={['center', 'center', 'flex-end']}
        >
          <Text color="customGrey.900" fontSize="1rem" marginRight="1rem">
            {t('common:pageLocation', {
              currentPageNumber: pageIndex + 1,
              totalNumberOfPages: numberOfPages
            })}
          </Text>
          <IconButton
            aria-label="previous page button"
            backgroundColor="buttonPrimary"
            borderRadius="10px"
            onClick={previousPage}
            disabled={!canPreviousPage}
            icon={
              <ChevronIcon width={10} height={10} transform="rotate(90deg)" />
            }
          />
          <IconButton
            aria-label="next page button"
            backgroundColor="buttonPrimary"
            borderRadius="10px"
            onClick={nextPage}
            disabled={!canNextPage}
            icon={
              <ChevronIcon width={10} height={10} transform="rotate(-90deg)" />
            }
          />
        </HStack>
      </VStack>
    );
  }
);

export default DataTable;
