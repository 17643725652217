import { useAppSettings } from '@/context/AppSettings';
import useTranslation from 'next-translate/useTranslation';
import Script from 'next/script';
import { getFallbackLanguage } from '@/helpers/lang';

export default function FreshDeskScript() {
  const config = useAppSettings();
  const { lang } = useTranslation();

  const freshDeskConfig = config?.features?.freshDesk;
  const widgetId = freshDeskConfig?.[getFallbackLanguage(lang)]?.widgetId;

  return (
    <>
      {Script({
        strategy: 'lazyOnload',
        children: `window.fwSettings={'widget_id':${widgetId},'locale':'${getFallbackLanguage(
          lang
        )}'};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();`
      })}
      <Script
        src={`https://euc-widget.freshworks.com/widgets/${widgetId}.js?v=${Date.now()}`}
        strategy="lazyOnload"
        onReady={() => {
          window.FreshworksWidget?.('hide', 'launcher');
        }}
      ></Script>
    </>
  );
}
