import { SpaceProps, StackProps, VStack } from '@chakra-ui/react';
import React from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn
} from 'react-hook-form';

type Props<T extends FieldValues> = React.PropsWithChildren &
  UseFormReturn<T> & {
    onSubmit?: SubmitHandler<any>;
    stackProps?: StackProps & SpaceProps;
  };

export function Form<T extends FieldValues>({
  onSubmit,
  children,
  stackProps,
  ...props
}: Props<T>) {
  return (
    <FormProvider {...props}>
      <VStack as="form" w="100%" onSubmit={onSubmit} {...stackProps}>
        {children}
      </VStack>
    </FormProvider>
  );
}
