import { useStepper } from '@/components/Stepper';
import { useSettings } from '@/components/UserSettings/SettingsContext';
import { useAuth } from '@/context/Auth';
import {
  Button,
  Checkbox,
  Divider,
  HStack,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { humanReadableBytes } from '../../../../helpers/humanReadableBytes';
import { ChevronIcon, IdIcon, PassportIcon } from '../../../../theme/Icons';
import DropZone from '../../../DropZone/DropZone';
import { HTTP } from '../../../Http';
import { APIDocumentType } from './MyDocuments.types';
import ProgressLine from './ProgressLine';

type UploadProps = {
  type: 'id_card' | 'passport';
};

export const endpoints: {
  [key in string]: string;
} = {
  id_card: 'user/id_cards',
  passport: 'user/passports'
};

const Upload = ({ type }: UploadProps) => {
  const [currentFiles, setCurrentFiles] = useState<{
    [index: number]: File;
  } | null>(null);
  const [errors, setErrors] = useState<any>(null);
  const [checkedConfirmation, setCheckedConfirmation] =
    useState<boolean>(false);
  const [inUpload, setInUpload] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<APIDocumentType | null>();
  const { getDocuments } = useSettings();
  const { back } = useStepper();
  const { t } = useTranslation();

  const maxFileSize = 20971520;
  const acceptedFiles = ['image/jpg', 'image/jpeg', 'image/png'];

  const files =
    type === 'id_card'
      ? [
          {
            key: 'front',
            label: t('account:frontIDCard')
          },
          {
            key: 'back',
            label: t('account:backIDCard')
          }
        ]
      : [
          {
            key: 'front',
            label: ''
          }
        ];

  const toast = useToast();
  const { userData } = useAuth();

  const displayAcceptedFilesTypes = acceptedFiles
    .map((el: string) => el.split('/')[1].toUpperCase())
    .join(', ');

  const onProgress = (curId: number, curValue: number) => {};

  const cleanImage = (
    index: number,
    newError?: string,
    deleteErrors: boolean = true
  ) => {
    if (!!deleteErrors) {
      const newErrors = () => {
        let temp = { ...errors };
        delete temp[index];
        return temp;
      };
      setErrors({ ...newErrors(), [index]: newError });
    } else {
      setErrors((errors: any) => ({ ...errors, [index]: newError }));
    }
    setUploaded(false);
    setInUpload(false);
    removeImage(index);
    setCurrentDoc(null);
    setCheckedConfirmation(false);
  };

  const handleSubmission = async () => {
    if (!userData?.id) return;
    setInUpload(true);

    let doc = currentDoc ? { ...currentDoc } : undefined;
    if (!doc || !doc.id) {
      // create doc
      try {
        const res = await HTTP.post(endpoints[type], {
          user: '/users/' + userData.id
        });
        doc = res.data;
        if (!doc || !doc.id) throw new Error('Wrong data from api');
        setCurrentDoc(doc);
      } catch {
        toast({
          title: t('account:uploadError'),
          description: <>{t('account:anErrorOccuredWhileSelectingFiles')}</>,
          status: 'error',
          position: 'top',
          duration: 4000,
          isClosable: true
        });
        setInUpload(false);
        return;
      }
    }

    if (currentFiles) {
      const [frontCard, backCard] = await Promise.allSettled(
        Object.values(currentFiles).map((curFile, curId) => {
          return new Promise(async (resolve, reject) => {
            const url =
              endpoints[type] + '/' + doc?.id + '/upload/' + files[curId].key;
            try {
              const form = new FormData();

              // send file
              form.append('file', curFile);

              const response = await HTTP.post(url, form, {
                onUploadProgress: ({
                  total,
                  loaded
                }: {
                  total: number;
                  loaded: number;
                }) => {
                  onProgress(curId, Math.round((loaded / total) * 100));
                }
              });

              resolve(response);
            } catch (err: any) {
              reject(err);
              // reject(err);
            }
          });
        })
      );

      const isFullFilled =
        type === 'id_card'
          ? frontCard.status === 'fulfilled' && backCard?.status === 'fulfilled'
          : frontCard.status === 'fulfilled';

      if (isFullFilled) {
        let lastData: APIDocumentType | undefined;
        const getUrl = endpoints[type] + '/' + doc?.id;
        try {
          const res = await HTTP.get(endpoints[type] + '/' + doc?.id);
          lastData = res.data;
          if (!lastData || !lastData.id) throw new Error('Wrong data from api');
        } catch (err) {
          console.error('error from GET ' + getUrl, err);
          // nothing to do, it will go to 1st step when lastData is empty
        }

        // success
        toast({
          title: t('account:documentUploaded'),
          description: t('account:uploadSuccessfull'),
          status: 'success',
          position: 'top',
          duration: 4000,
          isClosable: true
        });
        setUploaded(true);
        // reset form
        setCurrentFiles(null);
        setCheckedConfirmation(false);
        await getDocuments?.();

        if (lastData) {
          setCurrentDoc(lastData);
        }

        setInUpload(false);
        return;
      }

      if (type === 'id_card' && backCard?.status === 'rejected') {
        cleanImage(1, backCard?.reason?.data?.violations?.[0]?.message, false);
      }

      if (frontCard.status === 'rejected') {
        cleanImage(0, frontCard?.reason?.data?.violations?.[0]?.message, false);
      }
    }
  };

  const hasCountFiles =
    currentFiles &&
    Object.values(currentFiles).filter(Boolean).length === files.length;

  const removeImage = (index: number) => {
    let newCurrentFiles = { ...currentFiles };
    delete newCurrentFiles[index];
    setCurrentFiles(newCurrentFiles);
  };

  return (
    <VStack width="100%" align="baseline">
      <HStack marginBottom="3rem !important">
        {type === 'id_card' ? (
          <IdIcon w={10} h={10} />
        ) : (
          <PassportIcon w={10} h={10} />
        )}
        <Text as="h1" textStyle="h1">
          {t(
            `form:${
              type === 'id_card' ? 'uploadIDDocuments' : 'uploadPassport'
            }`
          )}
        </Text>
      </HStack>

      <Stack
        width="100%"
        spacing="2rem"
        direction={['column', 'row']}
        justifyContent="space-between"
      >
        {files.map((file, index) => (
          <DropZone
            key={index}
            title={file.label}
            acceptedFiles={acceptedFiles}
            maxFileSize={maxFileSize}
            error={errors?.[index]}
            onSetFile={(file?: File | null) => {
              file && setCurrentFiles({ ...currentFiles, [index]: file });
            }}
            onClean={() => {
              cleanImage(index);
            }}
          />
        ))}
      </Stack>

      <Text
        width="100%"
        textAlign="center"
        color="customGrey.900"
        role="contentinfo"
        marginBottom="3rem !important"
      >
        {displayAcceptedFilesTypes}. {t('account:maximumSize')}{' '}
        {humanReadableBytes(String(maxFileSize))}
      </Text>

      <Stack
        width="100%"
        direction={['column', 'row']}
        justifyContent="space-between"
        spacing="2rem"
      >
        <Checkbox
          data-testid="confirm-documents-checkbox"
          size="md"
          colorScheme="red"
          onChange={() => setCheckedConfirmation(!checkedConfirmation)}
          isChecked={checkedConfirmation}
          isDisabled={!hasCountFiles}
        >
          {t('account:confirmDocuments')}
        </Checkbox>
        {inUpload ? (
          <ProgressLine
            label={t('account:uploadPleaseWait')}
            requestDuration="3s" // todo: create state with % from HTTP
            backgroundColor="#8A8E99"
            visualParts={[
              {
                percentage: '6%',
                color: '#54001b'
              },
              {
                percentage: '22%',
                color: '#7e0333'
              },
              {
                percentage: '22%',
                color: '#9b023d'
              },
              {
                percentage: '50%',
                color: '#F00050'
              }
            ]}
          />
        ) : (
          <Button
            size="lg"
            onClick={handleSubmission}
            data-testid="send-my-documents-button"
            backgroundColor="buttonPrimary"
            disabled={!hasCountFiles || !checkedConfirmation}
          >
            {t('account:uploadDocuments')}
          </Button>
        )}
      </Stack>
      <Divider marginY="2rem !important" />
      <Button
        variant="link"
        onClick={back}
        leftIcon={<ChevronIcon w={8} h={10} transform="rotate(90deg)" />}
      >
        {t('account:previousStep')}
      </Button>
    </VStack>
  );
};

export default Upload;
