import React from 'react';
import { localKey } from '../components/BackToReality/BackToReality';
import { useForm } from 'react-hook-form';
import { HTTP } from '../components/Http';
import { useAuth } from '../context/Auth';
import setLanguage from 'next-translate/setLanguage';
import { APILoginResponse, APIUserType } from '@/context/Auth.types';
import { useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useLoginModal } from '@/context/LoginModal';
import { DomainLicense, getFullUrlByLicense } from '@/utils/multiDomains';

type useLoginProps = {
  onEnd?: (user?: APIUserType) => Promise<void> | void;
  callback?: (user: APIUserType) => Promise<void> | void;
  license?: keyof DomainLicense;
};

type LoginData = {
  username: string;
  password: string;
};

const useLogin = ({ onEnd, callback, license }: useLoginProps) => {
  const { setUserData, setIsLogged, isLogged, updateAuth, setLoginResult } =
    useAuth();
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();
  const { t, lang } = useTranslation();
  const router = useRouter();
  const form = useForm<LoginData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all'
  });
  const { onClose } = useLoginModal();
  const onSubmit = async (formValues: LoginData) => {
    return handleLoginSubmit(formValues);
  };

  const handleOthersStatus = async (data: APILoginResponse, cb: () => void) => {
    setUserData(data.user);
    setIsLogged(false);
    setLoading(false);
    // await onEnd?.(data.user);
    localStorage?.removeItem(localKey);
    cb?.();
  };

  const handleFailedLogin = async () => {
    setUserData(false);
    setIsLogged(false);
    setLoading(false);
    await onEnd?.();
  };

  const handleLoginSubmit = async (
    formValues: LoginData = form.getValues()
  ) => {
    try {
      setLoading(true);
      const response = await HTTP.post('user/login', {
        username: formValues.username.trim(),
        password: formValues.password
      });
      const { data } = response as { data: APILoginResponse };
      setLoginResult?.(data.result);

      switch (data.result) {
        case 'LOGIN_SUCCESSFUL':
          onClose();
          // catch invisible error on some browser (safari, )
          await setLanguage(data.user?.preferredLanguage).catch((err) =>
            console.log('next-translate setLanguage ERROR:', err)
          );
          document.cookie = `NEXT_LOCALE=${data.user?.preferredLanguage}; max-age=31536000; path=/`;
          localStorage?.setItem(localKey, '1');
          updateAuth(data.user);
          setLoading(false);

          if (
            data.user?.identity?.error === 'EPIS_HIT' ||
            data.user?.identity?.error === 'UNDER_21'
          ) {
            router.push(
              getFullUrlByLicense(license || 'MAIN', lang) + '/error/epis'
            );
          } else {
            await callback?.(data.user);
            await onEnd?.(data.user);
          }
          break;
        case 'INVALID_CREDENTIALS':
          toast({
            title: t('common:invalidCredentials'),
            status: 'error',
            position: 'top-right'
          });
          setLoading(false);
          break;
        case 'SELF_EXCLUDED':
          handleOthersStatus(data, () => {
            onClose();
            router.push(
              license
                ? getFullUrlByLicense(license, lang) + '/self-excluded'
                : '/self-excluded'
            );
          });
          break;
        case 'EXCLUDED':
          handleOthersStatus(data, () => {
            onClose();
            // router.push('/error/epis');
            router.push(
              getFullUrlByLicense(license || 'MAIN', lang) + '/error/epis'
            );
          });
          break;
        case 'USER_BANNED':
          handleOthersStatus(data, () => {
            onClose();
            router.push('/banned');
          });
          break;
        default:
          handleFailedLogin();
          break;
      }
      // if (data.result === 'LOGIN_SUCCESSFUL') {
      //   await setLanguage(data.user?.preferredLanguage);
      //   document.cookie = `NEXT_LOCALE=${data.user?.preferredLanguage}; max-age=31536000; path=/`;
      //   localStorage?.setItem(localKey, '1');
      //   updateAuth(data.user);
      //   setLoading(false);
      //   await callback?.(data.user);
      //   await onEnd?.(data.user);
      // } else {
      //   console.log('data login result: ', data.result);
      //   setUserData(data.user);
      //   setIsLogged(false);
      //   setLoading(false);
      //   console.log('from userLogin: ', data.user);
      //   await onEnd?.(data.user);
      //   localStorage?.removeItem(localKey);
      // }
    } catch (error) {
      // props.errors(!!error && error);
      handleFailedLogin();
    }
  };

  return {
    isLogged,
    form,
    loading,
    onSubmit: form.handleSubmit(onSubmit),
    handleLoginSubmit
  };
};

export default useLogin;
