let availableLanguagesObject;
try {
  availableLanguagesObject = JSON.parse(
    process.env.AVAILABLE_LANGUAGES_JSON ??
      '{"en": "English","fr": "Français", "it": "Italiano","nl": "Nederlands","ro": "Română","el": "Ελληνικά","ar": "العربية","zh": "中文"}'
  );
} catch (e) {
  console.error('Error parsing process.env.AVAILABLE_LANGUAGES_JSON', e);

  availableLanguagesObject = {
    en: 'English',
    fr: 'Français',
    it: 'Italiano',
    nl: 'Nederlands',
    ro: 'Română',
    el: 'Ελληνικά',
    ar: 'العربية',
    zh: '中文'
  };
}

// const availableLanguages = Object.keys(availableLanguagesObject)
const availableLanguages = Object.keys(availableLanguagesObject);

const fallbackLanguage = process.env.FALLBACK_LANGUAGE || 'en';

module.exports = {
  availableLanguages,
  availableLanguagesObject,
  fallbackLanguage,
  locales: [...availableLanguages, 'default'],
  defaultLocale: 'default',
  localeDetection: false,
  pages: {
    '*': ['common', 'footer', 'detail', 'seo', 'notifications', 'smartico'],
    '/me/wallet/[tab]': ['wallet', 'form', 'account'],
    '/me/settings/[tab]': ['settings', 'wallet'],
    '/me/account/[tab]': [
      'account',
      'form',
      'forgot-password',
      'responsible-gaming',
      'register'
    ],
    '/sports': ['wallet', 'form'],
    '/[segment]': [
      'catalog',
      'wallet',
      'form',
      'common',
      'tournament',
      'jackpot'
    ],
    '/[segment]/news': ['common', 'news', 'wallet'],
    '/[segment]/news/[slug]': ['common', 'news', 'wallet'],
    '/[segment]/news/tags/[slug]': ['common', 'news', 'wallet'],
    '/[segment]/category/[slug]': ['catalog', 'tournament', 'wallet'],
    '/sports-pmu/[slug]': ['form', 'wallet'],
    '/dice/virtual-sports': ['catalog', 'wallet'],
    '/[segment]/games/[id]/[slug]': ['detail', 'catalog', 'wallet'],
    '/[segment]/register': ['register', 'form', 'wallet'],
    '/[segment]/forgot-password': ['common', 'forgot-password'],
    '/[segment]/password-reset': ['common', 'forgot-password'],
    '/[segment]/self-excluded': ['account'],
    '/[segment]/payment-success': ['wallet'],
    '/[segment]/tournaments': ['common', 'tournament', 'catalog', 'wallet'],
    '/[segment]/tournaments/[id]/[slug]': ['common', 'tournament', 'wallet'],
    '/[segment]/payment-failure': ['wallet'],
    '/[segment]/info/[slug]': ['catalog', 'wallet'],
    '/register': ['register', 'form', 'wallet'],
    '/payment-success': ['wallet'],
    '/payment-failure': ['wallet'],
    '/forgot-password': ['common', 'forgot-password'],
    '/underage': ['register', 'catalog'],
    '/error-register': ['register'],
    '/_altenar/forgot-password': ['forgot-password'],
    '/_altenar/me/wallet/[tab]': ['wallet', 'form', 'account'],
    '/_altenar/register': ['register', 'form', 'wallet'],
    '/account-created': ['register'],
    '/[segment]/section/[slug]': ['catalog', 'wallet'],
    '/sports-predictor': ['euro-2024', 'wallet', 'seo'],
    '/sports-euro-2024': ['euro-2024', 'wallet'],
    '/sports-golden-scores-euro2024': ['euro-2024', 'wallet']
  }
};
