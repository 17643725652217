import { DataStore, Game } from '@/components/GamesCatalog/types';
import {
  CompletionRatioType,
  RegisterStepType,
  SlideRangeType
} from '@/components/Register/RegisterForm.types';
import { PaymentMethod } from '@/components/UserSettings/Wallet/CashIn/CashInForm';
import { APIUserType } from '@/context/Auth.types';
import { DepositLimit, WalletBalanceType } from '@/context/Wallet';
import { GameCatalogItem, GameDataStore } from '@/services/getCatalog';
import { DomainLicense } from '@/utils/multiDomains';
import getConfig from 'next/config';
import Script from 'next/script';
import { PaymentProvider } from '@/pages/[segment]/payment-success';

const { publicRuntimeConfig } = getConfig();
const GTM_ID = publicRuntimeConfig?.currentAppConfig?.gtmId || '';
const GTM_ID_GtmIdDomainProxified =
  publicRuntimeConfig?.currentAppConfig?.gtmIdDomainProxified || '';
const GTM_NOSCRIPT_SRC = (license: keyof DomainLicense) =>
  publicRuntimeConfig?.currentAppConfig?.gtmConfig?.[license].noScriptSrc || '';
const appNameTag = String(
  publicRuntimeConfig?.currentAppConfig?.appName || 'goldeneye'
).toLowerCase();
// export const GTM_ID = 'GTM-MJNXTG9'; // created by adel for testing

const isGtmActive = process.env.NODE_ENV !== 'development';

/* 
 The block scriptHead is moved to _document.tsx
*/
// const scriptHead = (license: keyof DomainLicense) => {
//   return isGtmActive && GTM_ID && GTM_SRC(license)
//     ? Script({
//         id: 'googletagmanager',
//         strategy: 'afterInteractive',
//         children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// ${GTM_SRC(license)}+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','${GTM_ID}');`
//       })
//     : null;
// };

const noscriptBody = (
  license: keyof DomainLicense,
  isGtmProxified = !!GTM_ID_GtmIdDomainProxified
) =>
  isGtmActive &&
  GTM_NOSCRIPT_SRC(license) &&
  `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
${
  isGtmProxified
    ? `<iframe src="${GTM_NOSCRIPT_SRC(
        license
      )}ns.html?id=${GTM_ID_GtmIdDomainProxified}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    : null
}
`;

if (typeof window !== 'undefined') window.dataLayer = window.dataLayer || [];

const _push = (...data: object[]) => {
  if (!isGtmActive) {
    console.log('DISABLED GTM PUSH : ' + GTM_ID, ...data);
    data.forEach((d) => {
      (d as any).eventCallback();
    });
    return;
  }

  window.dataLayer.push(...data);
};

const _prepareData = (
  event: string,
  eventName: string,
  data: {
    page?: PageDataType;
    game?: GameCatalogItem;
    user?: APIUserType;
    wallet?: UserWalletDataType;
    ecommerce?: EcommerceDataType;
    form?: FormDataType;
    section?: string;
    name?: string;
    dataStore?: DataStore | GameDataStore;
  },
  eventCallback: () => void = () => {},
  eventTimeOut: number = 2000 // needed so callback gets called even if something goes wrong while pushing tags
) => {
  return {
    event,
    eventTimeOut,
    eventCallback,
    ...(eventName ? { eventName } : {}),
    ...(data.name ? { name: data.name } : {}),
    ...(data.page
      ? { page: { ...data.page, gp_referrer: getGPReferrer() } }
      : {}),
    ...(data.game
      ? {
          games: {
            name: data.game?.slug,
            section: data.section,
            // GET THEME DIRECTLY OR RETRIEVE IT FROM THE DATASTORE
            theme: data.game?.themes
              ?.map((x) =>
                x['name']
                  ? x['name']
                  : data.dataStore?.themes?.[x as any]?.name || x
              )
              .join(),
            // GET CATEGORY SLUG DIRECTLY OR RETRIEVE IT FROM THE DATASTORE
            categories: data.game?.categories
              ?.map((category) =>
                (category as any)['slug']
                  ? (category as any)['slug']
                  : data.dataStore?.categories?.[category]?.slug || category
              )
              .join(','),
            // GET EDITOR DIRECTLY OR RETRIEVE IT FROM THE DATASTORE
            editor: (data.game?.provider as any)?.name
              ? (data.game?.provider as any)?.name
              : data.dataStore?.providers?.[data.game?.provider as string]
                  ?.name || data.game?.provider,
            tags: data.game?.tags?.join()
          }
        }
      : {}),
    ...(data.user
      ? {
          user: {
            id: data.user?.id,
            last_visit: data.user?.lastLogin,
            favoriteSite: getFavoriteSite(data.user),
            ...(data.wallet
              ? {
                  wallet: data.wallet
                }
              : {})
          }
        }
      : {}),
    ...(data.ecommerce ? { ecommerce: data.ecommerce } : {}),
    ...(data.form || {})
  };
};

// PAGE

export type PageDataType = {
  name?: string;
  language?: string;
  referrer?: string;
  gameCategoryName?: string;
  gp_referrer?: string | null;
};

type PageViewProps = {
  page: PageDataType;
  game?: Game | GameCatalogItem;
  user?: APIUserType;
  wallet?: WalletBalanceType;
  form?: FormDataType;
  section?: string;
  dataStore?: object;
};

const pageView = ({
  page,
  game,
  user,
  wallet,
  form,
  section,
  dataStore
}: PageViewProps) => {
  _push(
    _prepareData(`${appNameTag}-steps`, 'page_view', {
      page,
      game,
      user,
      wallet: wallet ? { amount: Number(wallet.amount || 0) / 100 } : undefined,
      form,
      section,
      dataStore
    })
  );
};

// GAME

type GameEventType =
  | 'game_launch'
  | 'demo_game_launch'
  | 'game_favorites'
  | 'game_share'
  | 'game_rate';
const gameEvent = (
  eventName: GameEventType,
  game?: GameCatalogItem,
  user?: APIUserType,
  wallet?: WalletBalanceType,
  section?: string,
  dataStore?: DataStore | GameDataStore
) =>
  _push(
    _prepareData('game-event', eventName, {
      game,
      user,
      section,
      wallet: wallet ? { amount: Number(wallet.amount || 0) / 100 } : undefined,
      dataStore
    })
  );

// WALLET

type WalletEventType =
  | 'first_time_deposit'
  | 'deposit'
  | 'payout_request'
  | 'deposit_limit_demand';
type UserWalletDataType = {
  amount: number;
  balance_moves?: number;
};

const _prepareWalletEvent = (
  eventName:
    | 'first_time_deposit'
    | 'deposit'
    | 'payout_request'
    | 'deposit_limit_demand',
  balance_moves: number | undefined,
  user: APIUserType | undefined,
  wallet: WalletBalanceType | DepositLimit | undefined
) =>
  _prepareData('wallet-event', eventName, {
    page: { gp_referrer: getGPReferrer() },
    user,
    wallet: wallet
      ? {
          amount: Number(wallet.amount || 0) / 100,
          ...(balance_moves ? { balance_moves: balance_moves / 100 } : {})
        }
      : undefined
  });

const walletEvent = (
  eventName: WalletEventType,
  balance_moves?: number,
  user?: APIUserType,
  wallet?: WalletBalanceType | DepositLimit
) => _push(_prepareWalletEvent(eventName, balance_moves, user, wallet));

// FORM

type FormEventType =
  | 'login_open'
  | 'login'
  | 'registration_start'
  | 'registration_steps'
  | 'registration_complete';
type FormDataType = {
  name?: string;
  steps?: string;
  registrationMean?: string;
};

type RegistrationEventType = {
  currentSlide: SlideRangeType;
  progression: RegisterStepType;
  language: string;
  mean?: 'classic_form' | 'itsme';
  userId?: string;
  user?: APIUserType;
};

const formEvent = (
  eventName: FormEventType,
  formSteps?: string,
  formName?: string,
  user?: APIUserType,
  wallet?: WalletBalanceType
) =>
  _push(
    _prepareData('form-event', eventName, {
      page: { gp_referrer: getGPReferrer() },
      user,
      wallet: wallet ? { amount: Number(wallet.amount || 0) / 100 } : undefined,
      form: {
        ...(formName ? { name: formName } : {}),
        ...(formSteps ? { steps: formSteps } : {})
      }
    })
  );

// NOTIFICATIONS
type NotificationEventType = 'notification_click';
const notificationEvent = (
  eventName: NotificationEventType,
  name: string,
  user?: APIUserType
) =>
  _push(
    _prepareData('notification-event', eventName, {
      page: { gp_referrer: getGPReferrer() },
      user,
      name
    })
  );

// REGISTRATION

const stepIdToStepName = {
  'step1-0%': 'email',
  'step1-33%': 'itsme-validation',
  'step1-100%': 'email', // used when click on back btn
  'step2-0%': 'registration-mean',
  'step2-50%': 'personal-info',
  'step2-100%': 'address-info',
  'step3-50%': 'user-info',
  'step3-100%': 'confirmation',
  'step4-100%': 'email-confirmation'
};

export const registrationEvent = ({
  currentSlide,
  progression,
  language,
  userId,
  user,
  mean
}: RegistrationEventType) => {
  const progressionValues = Object.values(progression);
  const completionRatio: CompletionRatioType =
    progressionValues[currentSlide - 1];
  const stepName = (stepIdToStepName as any)[
    `step${currentSlide}-${completionRatio}%`
  ];

  pageView({
    page: {
      name: `register/${stepName}`,
      language
    },
    user,
    form: { registrationMean: mean }
  });
  // console.log('GTM registrationEvent', {
  //   currentSlide,
  //   progression,
  //   language,
  //   userId,
  //   user,
  //   mean
  // });

  if (mean === 'itsme' && !progression['step1']) return;

  const isFirstStep = currentSlide === 1 && progression['step1'] === 0;
  const isLastStep =
    currentSlide === progressionValues.length && completionRatio === 100;
  const isConfirmationStep = currentSlide === 3 && completionRatio === 100;

  // if (completionRatio === 0) {
  //   // this check is necessary for type safety of stepName
  //   return;
  // }

  // console.log({ currentSlide, completionRatio, stepName });

  if (currentSlide === 1 && progression['step1'] === 0) {
    formEvent('registration_start');
  }
  if (
    stepName &&
    stepName !== 'unknown-event' &&
    !isFirstStep &&
    !isConfirmationStep
  ) {
    formEvent('registration_steps', stepName);
  }
  if (isConfirmationStep) {
    formEvent(
      'registration_complete',
      undefined,
      undefined,
      user ||
        ({
          id: userId
        } as APIUserType)
    );
  }
  if (stepName === 'unknown-event') {
    console.error(
      `GTM registrationEvent mistake, step${currentSlide} with ${completionRatio}% completionRatio is defined as an unknown-event. Please fix this.`
    );
  }
};

type AutoExclusionEventType =
  | 'temporary_auto-exclusion'
  | 'permanent_auto-exclusion'
  | 'account_reactivation';
const autoExclusionEvent = (
  eventName: AutoExclusionEventType,
  user?: APIUserType,
  wallet?: WalletBalanceType
) =>
  _push(
    _prepareData('auto-exclusion', eventName, {
      page: { gp_referrer: getGPReferrer() },
      user,
      wallet: wallet ? { amount: Number(wallet.amount || 0) / 100 } : undefined
    })
  );

// for future version
const menuEvent = () => {};
const filterEvent = () => {};
const searchEvent = () => {};

// E COMMERCE

type EcommerceEventType =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'begin_checkout'
  | 'purchase'
  | 'refund';
type EcommerceDataType = {
  currency: 'EUR';
  value?: number;
  payment_type: string;
  transaction_id?: string;
  items: {
    item_id: string;
    item_name: 'deposit' | 'payout';
    item_brand?: string;
    item_category?: string;
    item_category2: string | null;
    price?: number;
    quantity?: 1;
  }[];
};
type selectedPaymentMethod = 'provider' | 'method';

const _prepareEcommerceEvent = (
  eventName: EcommerceEventType,
  price?: number,
  paymentMethod?: Pick<PaymentMethod, selectedPaymentMethod>,
  user?: APIUserType,
  wallet?: WalletBalanceType,
  isFirstDeposit?: boolean,
  transId?: string,
  eventCallback?: () => void
) => {
  const payment_type = paymentMethod
    ? paymentMethod.provider + '-' + paymentMethod.method
    : 'unknown';

  return _prepareData(
    eventName,
    '',
    {
      // switched event with eventName (new payload needed)
      page: { gp_referrer: getGPReferrer() },
      user,
      wallet: wallet ? { amount: Number(wallet.amount || 0) / 100 } : undefined,
      ecommerce: {
        currency: 'EUR',
        payment_type,
        ...(price ? { value: price / 100 } : {}),
        ...(transId ? { transaction_id: transId } : {}),
        items: [
          {
            item_id: isFirstDeposit ? '1' : eventName === 'refund' ? '3' : '2',
            item_name: eventName === 'refund' ? 'payout' : 'deposit',
            item_category: isFirstDeposit ? 'first_time' : 'returning',
            item_category2: getSiteName(),
            item_brand: payment_type,
            ...(price
              ? {
                  quantity: 1,
                  price: price / 100
                }
              : {})
          }
        ]
      }
    },
    eventCallback
  );
};

const ecommerceEvent = (
  eventName: EcommerceEventType,
  price?: number,
  paymentMethod?: Pick<PaymentMethod, selectedPaymentMethod>,
  user?: APIUserType,
  wallet?: WalletBalanceType,
  isFirstDeposit?: boolean,
  transId?: string,
  eventCallback?: () => void
) => {
  return _push(
    _prepareEcommerceEvent(
      eventName,
      price,
      paymentMethod,
      user,
      wallet,
      isFirstDeposit,
      transId,
      eventCallback
    )
  );
};

const purchaseDepositEvent = (
  paymentMethod: Pick<PaymentMethod, selectedPaymentMethod>,
  userData?: APIUserType,
  balance?: WalletBalanceType,
  amount?: number,
  transactionId?: string
) => {
  const eCommerceEventObject = _prepareEcommerceEvent(
    'purchase',
    amount,
    paymentMethod,
    userData,
    balance,
    !userData?.alreadyDeposited,
    transactionId
  );

  const walletEventObject = _prepareWalletEvent(
    !userData?.alreadyDeposited ? 'first_time_deposit' : 'deposit',
    amount,
    userData,
    balance
  );
  _push(eCommerceEventObject, walletEventObject);
};

const GTM = {
  // scriptHead,
  noscriptBody,
  pageView,
  gameEvent,
  ecommerceEvent,
  formEvent,
  notificationEvent,
  registrationEvent,
  autoExclusionEvent,
  walletEvent,
  purchaseDepositEvent
};
export default GTM;

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

type SegmentByDomain = {
  [key: string]: string;
};

const domains = publicRuntimeConfig?.currentAppConfig?.domains;

const segmentByDomain: SegmentByDomain = {
  hub: domains?.MAIN,
  casino: domains?.A,
  dice: domains?.B,
  sports: domains?.F
};
const segmentByLicense: {
  [key: string]: string;
} = {
  'A+': 'casino',
  'B+': 'dice',
  'F+': 'sports'
};

export const getGPReferrer = () => {
  const referrer = document.referrer;
  if (!referrer) return null;

  const { hostname } = new URL(referrer);

  const segment = Object.keys(segmentByDomain).find((segment: string) => {
    return segmentByDomain[segment] === hostname;
  });

  return segment || null;
};

export const getFavoriteSite = (user: APIUserType) => {
  return user.originLicense ? segmentByLicense[user.originLicense] : null;
};

export const getSiteName = () => {
  const hostname = window.location.hostname;
  if (!hostname) return null;

  const segment = Object.keys(segmentByDomain).find((segment: string) => {
    return segmentByDomain[segment] === hostname;
  });

  return segment || null;
};
