import { HTTP } from '@/components/Http';
import {
  APIDocumentType,
  KycAllDocumentsType
} from '@/components/UserSettings/Account/MyDocuments/MyDocuments.types';
import { UserContext } from '@/context/Auth';
import { WalletBalanceType, WalletContext } from '@/context/Wallet';
import { Button, Text, useToast, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { DisplayedDocuments } from './Account/MyDocuments/MyDocuments';
import { PaymentMethod } from './Wallet/CashIn/CashInForm';
import { BankAccountPropsTypes } from './Wallet/CashOut/steps/CashoutForm.types';
import { UserBalanceType } from './Wallet/FastDeposit';
import { GamingHallEntity } from './Wallet/GamingHallCashOut/GHSelector';

type DocumentStatusMessages = {
  [key: string]: {
    message: ReactNode;
    status: boolean;
  };
};

type SettingsProps = {
  balance?: WalletBalanceType;
  minCashOut: number;
  maxGHCashOut: number;
  sliderValue: number | string;
  setSliderValue?: Dispatch<SetStateAction<number | string>>;
  isLoading: boolean;
  bankAccounts: BankAccountPropsTypes[];
  currentStatus: string;
  currentDocuments: APIDocumentType[];
  selectedBankAccount: BankAccountPropsTypes;
  defaultBankAccount: BankAccountPropsTypes;
  setSelectedBankAccount?: Dispatch<SetStateAction<BankAccountPropsTypes>>;
  getBankAccounts?: () => Promise<void>;
  getDefaultBankAccount?: () => Promise<void>;
  getDocuments?: () => Promise<void>;
  changeDefaultBankAccount?: (
    bankAccount: BankAccountPropsTypes
  ) => Promise<void>;
  documentStatusMessages?: DocumentStatusMessages;
  tabsInfos?: {
    [key: string]: any | null;
  };
  resetSelectedBankAccount?: () => void;
  paymentMethods: PaymentMethod[];
  selectedGamingHall?: GamingHallEntity;
  setSelectedGamingHall?: Dispatch<
    SetStateAction<GamingHallEntity | undefined>
  >;
};

export const Settings = createContext<SettingsProps>({
  sliderValue: 15,
  maxGHCashOut: 300000,
  minCashOut: 1500,
  isLoading: false,
  bankAccounts: [],
  currentStatus: '',
  currentDocuments: [],
  selectedBankAccount: { id: '', user: '', iban: '', bic: '' },
  defaultBankAccount: { id: '', user: '', iban: '', bic: '' },
  paymentMethods: []
});

export const useSettings = () => useContext(Settings);

type SettingsProviderProps = {
  children: ReactElement;
  paymentMethods?: PaymentMethod[];
  gaminHallCashoutMax?: number;
};

const initBankAccount = { id: '', user: '', iban: '', bic: '' };

export const SettingsProvider = ({
  children,
  paymentMethods,
  gaminHallCashoutMax
}: SettingsProviderProps) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { balance, isRetrieved } = useContext(WalletContext);

  const minCashOut = 1500;
  const [maxGHCashOut, setMaxGHCashOut] = useState<number>(
    Math.min(balance?.amount || 0, gaminHallCashoutMax || 0)
  );

  const [sliderValue, setSliderValue] = useState<number | string>(minCashOut);

  const { isLogged } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [currentDocuments, setCurrentDocuments] = useState<
    KycAllDocumentsType[]
  >([]);
  const [currentStatus, setCurrentStatus] = useState<string>('');
  const [bankAccounts, setBankAccounts] = useState<BankAccountPropsTypes[]>([]);

  const [selectedBankAccount, setSelectedBankAccount] =
    useState<BankAccountPropsTypes>({ id: '', user: '', iban: '', bic: '' });

  const [defaultBankAccount, setDefaultBankAccount] =
    useState<BankAccountPropsTypes>({ id: '', user: '', iban: '', bic: '' });

  const resetSelectedBankAccount = () => {
    setSelectedBankAccount(initBankAccount);
  };

  const [selectedGamingHall, setSelectedGamingHall] =
    useState<GamingHallEntity>();

  const documentStatusMessages: DocumentStatusMessages = {
    missing: {
      message: (
        <Text fontSize="1.2rem" fontWeight="bold">
          {t('form:uploadNewId')}
        </Text>
      ),
      status: currentStatus === 'missing'
    },
    uploaded: {
      message: (
        <Text fontSize="1.2rem" fontWeight="bold">
          {t('form:currentlyVerifying')}
        </Text>
      ),
      status: currentStatus === 'uploaded'
    },
    rejected: {
      message: (
        <Text fontSize="1.2rem" fontWeight="bold">
          {t('form:idIsRejected')}
        </Text>
      ),
      status: currentStatus === 'rejected'
    },
    invalidated: {
      message: (
        <Text fontSize="1.2rem" fontWeight="bold">
          {t('form:idIsInvalid')}
        </Text>
      ),
      status: currentStatus === 'invalidated'
    },
    expired: {
      message: (
        <Text fontSize="1.2rem" fontWeight="bold">
          {t('form:idHasExpired')}
        </Text>
      ),
      status: currentStatus === 'expired'
    },
    approved: {
      message: (
        <VStack align="baseline">
          <Text fontSize="1.2rem" fontWeight="bold">
            {t('form:idIsApproved')}
          </Text>
          <DisplayedDocuments />
        </VStack>
      ),
      status: currentStatus === 'approved'
    }
  };

  const tabsInfos = {
    'my-profile': null,
    licenses: null,
    'responsible-gaming': null,
    'my-documents':
      currentStatus &&
      currentStatus !== 'approved' &&
      currentStatus !== 'uploaded' ? (
        <Text
          backgroundColor="buttonPrimary"
          borderRadius="full"
          color="white"
          fontSize="12px"
          fontWeight="bold"
          padding="0 7px"
          position="absolute"
          top="2px"
          right="-5px"
        >
          !
        </Text>
      ) : null
  };

  const load = async () => {
    try {
      setIsLoading(true);
      await getDocuments();
      await getBankAccounts();
      await getDefaultBankAccount();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isLogged && load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    setMaxGHCashOut(Math.min(balance?.amount || 0, gaminHallCashoutMax || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  const getDocuments = async () => {
    try {
      const { data } = await HTTP.get(
        '/user/documents?limit=1&order[createdAt]=desc'
      );
      // const idCardsOnly = data.filter(
      //   (el: { type: string }) => el.type === 'id_card'
      // );
      setCurrentDocuments(data);
      setCurrentStatus(data?.[0]?.status);
    } catch (error) {
      setCurrentDocuments([]);
      toast({
        title: 'Error with API',
        description: (
          <>
            {t('common:somethingWrongHappenned')}
            <Button
              variant="outline"
              style={{ marginTop: '1em' }}
              onClick={getDocuments}
            >
              {t('common:retry')}
            </Button>
          </>
        ),
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
    }
  };

  const getBankAccounts = async () => {
    try {
      const { data } = await HTTP.get(`/user/payment_methods`);
      setBankAccounts(data);
      const defaultBankAccount = data.filter(
        (bank: BankAccountPropsTypes) => bank.default
      )?.[0];
      setDefaultBankAccount(defaultBankAccount);
    } catch (error) {
      toast({
        title: t('account:errorLoadingBank'),
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
    }
  };

  const changeDefaultBankAccount = async (
    bankAccount: BankAccountPropsTypes
  ) => {
    try {
      const response = await HTTP.patch(`/user/default_payment_method`, {
        defaultPaymentMethod: `bank_accounts/${bankAccount.id}`
      });
      //setDefaultBankAccount(response?.data?.defaultPaymentMethod);
      getBankAccounts();
    } catch (error) {
      toast({
        title: t('account:errorSettingDefaultBankAccount'),
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
    }
  };

  const getDefaultBankAccount = async () => {
    try {
      const response = await HTTP.get(`/user/default_payment_method`);
      setDefaultBankAccount(response?.data);
    } catch (error: any) {
      // if user hasn't bank account, no need to trigger a toast
      if (error?.status !== 404) {
        toast({
          title: error.data.title || t('account:errorLoadingBank'),
          description: error.data.detail,
          status: 'error',
          position: 'top',
          duration: 4000,
          isClosable: true
        });
      }
    }
  };

  return (
    <Settings.Provider
      value={{
        balance,
        minCashOut,
        maxGHCashOut,
        sliderValue,
        setSliderValue,
        isLoading,
        currentDocuments,
        currentStatus,
        bankAccounts,
        selectedBankAccount,
        setSelectedBankAccount,
        defaultBankAccount,
        getBankAccounts,
        changeDefaultBankAccount,
        getDocuments,
        documentStatusMessages,
        tabsInfos,
        resetSelectedBankAccount,
        paymentMethods: paymentMethods ?? [],
        selectedGamingHall,
        setSelectedGamingHall
      }}
    >
      {children}
    </Settings.Provider>
  );
};
