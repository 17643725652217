import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@chakra-ui/react';
import { useExtraProps } from '@/pages/_app';

type HeaderWrapperProps = BoxProps &
  PropsWithChildren & {
    containerProps?: ContainerProps;
  };
export const HeaderWrapper: FC<HeaderWrapperProps> = ({
  children,
  containerProps,
  borderBottom = '1px solid #FFFFFF33',
  ...props
}) => {
  const { license } = useExtraProps();
  // const isDesktop = useMediaQuery('(min-width: sm)');
  return (
    <Box
      backdropFilter={'blur(10px)'}
      bg={'header.bg'}
      borderBottom={borderBottom}
      // overflow={'hidden'}
      alignItems={'stretch'}
      display={'flex'}
      flexDirection={'row'}
      {...props}
    >
      <Container
        maxW={'container.xl'}
        padding={['0', '0 1rem']}
        flex={1}
        {...containerProps}
      >
        {children}
      </Container>
    </Box>
  );
};
