import { LoginView } from '@/components/Login/LoginModal';
import GTM from '@/helpers/googleTagManager';
import { DeepPartial, useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import dynamic from 'next/dynamic';
import { useExtraProps } from '@/pages/_app';
import useTranslation from 'next-translate/useTranslation';
import { getFallbackLanguage } from '@/helpers/lang';
import { BannerType } from '@/types/api/ge-strapi/banner';
import { useAppSettings } from '@/context/AppSettings';
const LoginModal = dynamic(() =>
  import('@/components/index').then((mod) => mod.LoginModal)
);

type LoginModalContextType = {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  view: LoginView;
  setView: Dispatch<SetStateAction<LoginView>>;
  isModalClosed?: boolean;
  setIsModalClosed?: Dispatch<SetStateAction<boolean>>;
  backgroundUrl: string | undefined;
};

export const LoginModalContext = createContext<LoginModalContextType>({
  onClose: () => {},
  onOpen: () => {},
  isOpen: false,
  view: 'full',
  setView: () => {},
  backgroundUrl: undefined
});

type FetchDataType = {
  bannerLang: string;
  license: string;
  loginBackgroundUrl: string | undefined;
};

export const LoginModalProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { license } = useExtraProps();
  const { lang } = useTranslation();
  const [view, setView] = useState<LoginView>('full');
  const [isModalClosed, setIsModalClosed] = useState<boolean>(false);
  const [fetchData, setFetchData] = useState<FetchDataType | undefined>();
  const config = useAppSettings();
  const enableLoginBackgroundFetch: boolean =
    config?.customStyle?.enableLoginBackgroundFetch ?? false;

  useEffect(() => {
    if (!enableLoginBackgroundFetch || !license) return;

    const bannerLang = getFallbackLanguage(lang);
    const isAlreadyFetched =
      fetchData?.bannerLang === bannerLang && fetchData?.license === license;

    if (isAlreadyFetched) return;

    const loginBannerFetcher = async (bannerLang: string, license: string) => {
      try {
        const data = await fetch(
          `/api/cms/banner?locale=${bannerLang}&licences=${license}&positions=login&random=true`
        );
        const result: DeepPartial<BannerType>[] = await data.json();
        const loginBackgroundUrl =
          result[0].attributes?.picture?.data?.attributes?.url;
        setFetchData({ bannerLang, license, loginBackgroundUrl });
      } catch (err) {
        setFetchData(undefined);
      }
    };

    void loginBannerFetcher(bannerLang, license);
  }, [enableLoginBackgroundFetch, license, lang, fetchData]);

  const onCloseModal = () => {
    setIsModalClosed(true);
  };

  return (
    <LoginModalContext.Provider
      value={{
        onClose,
        onOpen: () => {
          onOpen();
          GTM.formEvent('login_open');
        },
        isOpen,
        view,
        setView,
        isModalClosed,
        setIsModalClosed,
        backgroundUrl: fetchData?.loginBackgroundUrl
      }}
    >
      {children}
      <LoginModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        callback={(user) => {
          GTM.formEvent('login', undefined, 'login-modal-form', user);
        }}
        view={view}
        onCloseModal={onCloseModal}
      />
    </LoginModalContext.Provider>
  );
};

export const useLoginModal = () => useContext(LoginModalContext);
