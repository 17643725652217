import ActionRequired from '@/components/ActionRequired';
import { GameImage } from '@/components/GamesCatalog';
import { HTTP } from '@/components/Http';
import { Img, Stack, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useSettings } from '../../SettingsContext';
import ExpiresAtDate from './IdExpiresAtDate';

export const DisplayedDocuments = () => {
  const { currentDocuments } = useSettings();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: md)');
  const documentTypes =
    currentDocuments[0]?.type === 'id_card' ? ['front', 'back'] : ['front'];
  return currentDocuments[0]?.type === 'itsme' ? (
    <Stack direction={['column', 'column', 'column', 'row']}>
      <Text
        fontSize="1.2rem"
        fontWeight="700"
        display={'flex'}
        alignItems="center"
      >
        {t('account:validatedBy')}
        <Img src="/partners/itsme-logo.png" width={'150px'} />
      </Text>
    </Stack>
  ) : (
    <Stack direction={['column', 'column', 'column', 'row']}>
      {documentTypes.map((fileType, id) => (
        <GameImage
          key={id}
          src={`${HTTP.defaults.baseURL}/user${
            (currentDocuments[0] as any)?.[fileType]?.url
          }`}
          width={isMobile ? '100%' : '400px'}
          alt={fileType}
          isNativeImage={true}
          borderRadius={'0'}
        />
      ))}
    </Stack>
  );
};

const MyDocuments = () => {
  const { currentDocuments, currentStatus } = useSettings();
  const { t } = useTranslation();

  return (
    <div className="myDocuments" data-testid="MyDocuments">
      <ActionRequired>
        <VStack width="100%" align="baseline" spacing="2rem">
          <Stack
            direction={['column', 'column', 'column', 'row']}
            width="100%"
            marginBottom="2rem"
            justifyContent="space-between"
          >
            <Text as="h1" textStyle="h1">
              {t('account:identityVerification')}
            </Text>
            {currentDocuments[0]?.expiresAt && (
              <ExpiresAtDate dateString={currentDocuments[0]?.expiresAt} />
            )}
          </Stack>

          {currentStatus === 'approved' ? (
            <DisplayedDocuments />
          ) : (
            <Text
              fontSize="1.2rem"
              fontWeight="bold"
              data-testid="currently-verifying"
            >
              {t('form:currentlyVerifying')}
            </Text>
          )}
        </VStack>
      </ActionRequired>
    </div>
  );
};
export default MyDocuments;
