import WithFallbackComponent from '@/hoc/WithFallbackComponent';
import {
  ButtonProps,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { FC, useEffect, useRef, useState } from 'react';
import { SuggestedGame } from '.';
import { useCatalog } from '@/context/Catalog';
import { SearchIcon } from '@/theme/Icons';
import NoGameFallback from '../Fallback/NoGameFallback';
import { GameCatalogItem } from '@/services/getCatalog';
import { HTTP } from '../Http';
import { useAppSettings } from '@/context/AppSettings';
import { toRem } from '@/helpers/toRem';
// import { SuggestedGameMobile } from './Suggested';

const Search: FC<ButtonProps> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredGames, setFilteredGames] = useState<GameCatalogItem[]>([]);
  const searchRef = useRef(null);
  // const isMobile = useMediaQuery('(max-width: 35rem)');
  const { games, dataStore, licence } = useCatalog();
  const { t } = useTranslation();
  const [isNoResult, setIsNoResult] = useState(true);
  const [isServerSearching, setIsServerSearching] = useState(false);
  const { features } = useAppSettings();

  const closeAndCleanFilters = () => {
    setFilteredGames([]);
    onClose();
  };

  const searchGame = (search: string) => {
    if (!search) {
      setIsNoResult(true);
      setFilteredGames([]);
      return;
    }

    const filtered = games
      ?.filter((game) => {
        if (game.name) {
          return `${game?.name}${
            dataStore?.providers?.[game.provider as string]?.name
          }`
            .toLowerCase()
            .includes(search.toLowerCase());
        }
        return false;
      })
      .sort((a, b) => b.launchCount - a.launchCount);

    setFilteredGames(filtered);

    const { maxFiltered, apiPath, minChars } = features.gameServerSearch;

    if (
      !isServerSearching &&
      licence &&
      filtered.length < maxFiltered &&
      search.length >= minChars
    ) {
      setIsServerSearching(true);

      HTTP.post<{}, { data: GameCatalogItem[] }, { searchValue: string }>(
        apiPath,
        {
          searchValue: search
        }
      )
        .then(({ data: serverGames }) => {
          if (Array.isArray(serverGames)) {
            setFilteredGames(_.uniqBy([...filtered, ...serverGames], 'id'));
          }
        })
        .catch((e) => {
          console.log('searchGame error:', e);
        })
        .finally(() => {
          setIsServerSearching(false);
        });
    }

    setIsNoResult(false);
  };

  const debouncedSearchGame = _.debounce(searchGame, 700);

  const getGamesResult = () => {
    return filteredGames.map((game) => (
      <SuggestedGame key={game.id} game={game} />
    ));
  };

  // reset isNoResult when we close/open the search modal
  useEffect(() => {
    if (isOpen) return;
    setIsNoResult(true);
  }, [isOpen]);

  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label="search button"
        variant="ghost"
        icon={<SearchIcon width={toRem(35)} height={toRem(35)} />}
        data-testid="top-search-button"
        borderRadius={'10px'}
        w={toRem(36)}
        minWidth={toRem(36)}
        h={toRem(36)}
        minHeight={toRem(36)}
        border={0}
        transition={'all 0.3s ease'}
        _hover={{
          color: 'figma.neutral.1000',
          bg: 'figma.neutral.400'
        }}
        {...props}
      />
      <Drawer
        initialFocusRef={searchRef}
        onOverlayClick={closeAndCleanFilters}
        onEsc={closeAndCleanFilters}
        isOpen={isOpen}
        placement="top"
        onClose={closeAndCleanFilters}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            as={VStack}
            alignItems="baseline"
            width="100%"
            boxShadow="search"
            borderRadius="0 0 2rem 2rem"
            data-testid="search-games-header"
          >
            <HStack
              marginBottom={[0, 0, '1rem']}
              width="100%"
              justifyContent="space-between"
            >
              <VStack align="baseline">
                <Text fontSize="1.5rem" fontWeight="bold">
                  {t('catalog:search')}
                </Text>
                <Text fontSize="0.9rem" color="customGrey.900">
                  {t('catalog:findPerfectGame')}
                </Text>
              </VStack>
              <DrawerCloseButton />
            </HStack>

            <InputGroup>
              <Input
                ref={searchRef}
                data-testid="search-games-input"
                borderRadius="0"
                paddingY={['1rem', '2.5rem']}
                fontSize={['1rem', '2.5rem']}
                fontWeight="500"
                border="none"
                borderBottom="2px solid white"
                background="transparent"
                placeholder={t('catalog:gamesEditorsThemes')}
                marginBottom="0.5rem !important"
                _placeholder={{
                  color: 'oriaGrey66'
                }}
                onChange={(e) => debouncedSearchGame(e.target.value)}
              />
              {isServerSearching ? (
                <InputRightElement>
                  <CircularProgress
                    isIndeterminate
                    color="figma.neutral.900"
                    stroke="figma.neutral.100"
                  />
                </InputRightElement>
              ) : null}
            </InputGroup>
            {!isNoResult && (
              <Text fontSize="0.9rem" color="customGrey.900">
                {isServerSearching
                  ? '...'
                  : !filteredGames?.length
                  ? t('catalog:results_0', { count: filteredGames?.length })
                  : filteredGames?.length === 1
                  ? t('catalog:results_one', { count: filteredGames?.length })
                  : `${filteredGames?.length} ${t('catalog:results')}`}
              </Text>
            )}
          </DrawerHeader>

          <DrawerBody
            as={Grid}
            width="99.9%"
            maxWidth="1920px"
            gap="3rem"
            templateColumns={[
              'repeat(auto-fit, 1fr)',
              'repeat(auto-fit, 1fr)',
              'repeat(auto-fit, 20rem)'
            ]}
            templateRows="repeat(auto-fit, 20rem)"
            justifyContent="center"
            margin="0 auto"
            overflowX={['hidden']}
            data-testid="search-games-results"
          >
            {!isNoResult && (
              <WithFallbackComponent
                predicat={filteredGames?.length > 0}
                FallbackComponent={NoGameFallback}
              >
                {getGamesResult()}
              </WithFallbackComponent>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Search;
