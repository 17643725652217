// import { Provider } from '@/components/GameFilters/FilterProvider';
import {
  filterGamesButIgnoreCategory,
  filterOnlyByCategory
} from '@/helpers/filterGames';
// import useLocalStorage from '@/hooks/useLocalStorage';
import useSessionStorage from '@/hooks/useSessionStorage';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { BetLimit, Category } from '../components/GamesCatalog/types';
import { useCatalog } from './Catalog';
import { useRouter } from 'next/router';
import useLocalStorage from '@/hooks/useLocalStorage';
import { GameCatalogItem } from '@/services/getCatalog';
import useQueryParamInitializer from '@/hooks/useQueryParamInitializer';

export type InitialFieldsType = {
  exclusive?: boolean;
  providers: string[];
  themes: string[];
  features: string[];
  betLimit: BetLimit; // object
  // volatility?: number;
  bonuses: string[];
  // buyPass?: boolean; // boolean or (yes, no, all ?)
  // jackpots: string[];
  category: Category;
  volatility: number[];
};

export const InitialFields: InitialFieldsType = {
  category: { id: 'all', name: 'all' } as Category,
  exclusive: false,
  providers: [],
  themes: [],
  features: [],
  betLimit: {
    '@id': '',
    '@type': 'BetLimit',
    min: 0,
    max: 10000
  },
  volatility: [],
  bonuses: []
  // buyPass: undefined, // boolean or (yes, no, all ?)
  // jackpots: []
};

type FiltersProviderContextType = {
  filteredGames: GameCatalogItem[];
  filterFields: InitialFieldsType;
  setFilterFields: (
    value: InitialFieldsType | ((val: InitialFieldsType) => InitialFieldsType)
  ) => void;
  reset: (key: keyof InitialFieldsType) => void;
  resetAll: () => void;
  numberOfFilterSectionsActive: number;
  countPerCat: {
    [key: string]: number;
    total: number;
  };
  hasChanges: boolean;
  countByProvider: { [key: string]: number };
  hasUpdatedCategory: string;
};

export const FiltersContext = createContext<FiltersProviderContextType>({
  filteredGames: [],
  filterFields: InitialFields,
  setFilterFields: () => undefined,
  reset: () => undefined,
  resetAll: () => undefined,
  countPerCat: {
    total: 0
  },
  numberOfFilterSectionsActive: 0,
  hasChanges: false,
  countByProvider: {},
  hasUpdatedCategory: ''
});

export const FiltersProvider = ({ children }: PropsWithChildren) => {
  const { games, dataStore } = useCatalog();
  const categories = Object.keys(dataStore?.categories || {});
  const router = useRouter();
  const isQueryFiltered = Object.keys(InitialFields).find((elm) =>
    Object.keys(router.query).includes(elm)
  );

  const [filteredGames, setFilteredGames] = useState<GameCatalogItem[]>(games);

  const [filterFieldsStorage, setFilterFieldsStorage] =
    useLocalStorage<InitialFieldsType>('filterFields', InitialFields);

  const [hasUpdatedCategory, setHasUpdatedCategory] = useState<string>('');

  const [filterFields, setFilterFields] = useState<InitialFieldsType>({
    ...InitialFields,
    category: {
      id: 'all',
      name: String(router.query.slug) || 'all'
    } as Category
  });

  const updateFilterState = !isQueryFiltered
    ? setFilterFieldsStorage
    : setFilterFields;

  const getFilterState = !isQueryFiltered ? filterFieldsStorage : filterFields;

  const { updatedFilters } = useQueryParamInitializer(
    InitialFields,
    dataStore,
    updateFilterState
  );

  useEffect(() => {
    if (updatedFilters) setFilterFields(updatedFilters);
  }, [updatedFilters]);

  // filter the games but ignore category
  // calculate the counts per category
  // filter per category

  const [countPerCat, setCountPerCat] = useState<{
    [key: string]: number;
    total: number;
  }>({
    total: 0
  });

  const [countByProvider, setCountByProvider] = useState<{
    [key: string]: number;
  }>({});

  // const [hasChanges, setHasChanges] = useState(
  //   isEqual(filterFields, InitialFields)
  // );

  const reset = (key: keyof InitialFieldsType) => {
    updateFilterState({
      ...filterFields,
      [key]: InitialFields[key]
    });
  };

  const resetAll = () => {
    updateFilterState(InitialFields);
  };

  const countBy = (arr: GameCatalogItem[], prop: keyof GameCatalogItem) =>
    arr.reduce(
      (prev: { [key: string]: number }, curr) => (
        (prev[curr[prop] as keyof GameCatalogItem] =
          ++prev[curr[prop] as keyof GameCatalogItem] || 1),
        prev
      ),
      {}
    );

  useEffect(() => {
    // This function will be called whenever the route (including the slug) changes
    const handleRouteChange = () => {
      // 'shallow' is true if the change doesn't result in a full page reload

      // Access the slug from the router object
      const { slug } = router.query;

      if (!!slug) {
        setHasUpdatedCategory(slug as string);
      } else {
        setHasUpdatedCategory('all');
      }

      let data;
      try {
        data = JSON.parse(window.localStorage.getItem('filterFields') || '');
      } catch {}
      const storedFilters = data || {};

      // store updated slug category
      updateFilterState({
        ...storedFilters,
        category: {
          id: 'all',
          name: slug || 'all'
        } as Category
      });
    };

    // Attach the event listener
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    setCountByProvider(countBy(games, 'provider'));
    // Need to use games as depedency because we need to have the total number of games for each provider.
    // If we use filteredGames, the data changes continuously after each filter change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games]);

  useEffect(() => {
    const filtered = filterGamesButIgnoreCategory({
      filterFields: getFilterState,
      games
    });

    // count per cat
    setCountPerCat(
      categories.reduce(
        (res, cur) => {
          const count = filtered.filter((x) =>
            x.categories?.includes(cur)
          ).length;
          return {
            ...res,
            [cur]: count,
            total: res.total + count
          };
        },
        { total: 0 }
      )
    );

    setFilteredGames(
      filterOnlyByCategory({
        games: filtered,
        category: getFilterState.category
      })
    );
    // setHasChanges(!isEqual(filterFields, InitialFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFilterState, games]);

  // const arrayNotEmpty = (arr: any) => {
  //   return Array.isArray(arr) && arr.length > 1;
  // };

  const objectIsNotEmptyAndNotAll = (obj: any) => {
    return (
      obj &&
      typeof obj === 'object' &&
      obj['id'] !== 'all' &&
      obj['@type'] !== 'BetLimit' &&
      obj['@type'] !== 'Provider' &&
      obj['@type'] !== 'Category' &&
      Object.keys(obj).length
    );
  };

  const betLimitHasChanged = (betLimit: BetLimit | any): boolean => {
    if (
      betLimit &&
      typeof betLimit === 'object' &&
      betLimit.hasOwnProperty('max')
    ) {
      return betLimit.min > 0 || betLimit.max < 10000;
    }
    return false;
  };

  const hasProvider = (provider: any): boolean => {
    return provider && provider['@type'] === 'Provider';
  };

  const numberOfFilterSectionsActive = Object.values(getFilterState).reduce(
    (res, current) => {
      if (Array.isArray(current) && current.length > 1) {
        res += current?.length;
      } else if (objectIsNotEmptyAndNotAll(current)) {
        res += Object.keys(current as any).length;
      } else if (
        current === true ||
        betLimitHasChanged(current) ||
        hasProvider(current)
      ) {
        res += 1;
      }

      return res;
    },
    0
  );

  const hasChanges = numberOfFilterSectionsActive > 0;

  return (
    <FiltersContext.Provider
      value={{
        filteredGames,
        filterFields: getFilterState,
        setFilterFields: updateFilterState,
        reset,
        resetAll,
        countPerCat,
        hasChanges,
        numberOfFilterSectionsActive,
        countByProvider,
        hasUpdatedCategory
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
// export directly context with the useContext hooks
export const useFilters = () => useContext(FiltersContext);
