import { numericDateFormatWithoutTime, useFormatDate } from '@/hooks/useFormat';
import { CheckedIcon } from '@/theme/Icons';
import { HStack, Icon, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useSettings } from '../../SettingsContext';

const IdExpiresAtDate = ({ dateString }: { dateString: string }) => {
  const { t } = useTranslation();
  const { currentDocuments } = useSettings();
  return (
    <HStack>
      <Icon
        as={CheckedIcon}
        w={6}
        h={6}
        border="2px solid green"
        borderRadius="full"
        padding="3px"
      />
      <Text fontWeight="bold" fontSize="1.2rem">
        {t(
          `account:${
            currentDocuments[0].type === 'id_card' ||
            currentDocuments[0].type === 'itsme'
              ? 'idValidUntil'
              : 'passportValidUntil'
          }`,
          {
            date: useFormatDate(dateString, numericDateFormatWithoutTime)
          }
        )}
      </Text>
    </HStack>
  );
};

export default IdExpiresAtDate;
